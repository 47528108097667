import React from "react";
import "./HowToRegister.css";
import howToPayInService_01 from "../../assets/images/howToPayInService/howToPayInService_01.png";
import howToPayInService_02 from "../../assets/images/howToPayInService/howToPayInService_02.png";
import howToPayInService_03 from "../../assets/images/howToPayInService/howToPayInService_03.png";
import howToPayInService_04 from "../../assets/images/howToPayInService/howToPayInService_04.png";
import howToPayInService_05 from "../../assets/images/howToPayInService/howToPayInService_05.png";

const HowToRegister = () => {
  const renderDatas = [
    {
      src: howToPayInService_01,
      title: "1. PC 또는 App 에서 로그인",
      text: "https://edu.toddlier.co.kr 에서 회원가입 후 로그인을 해 주세요. 자녀는 2명까지 프로필을 생성할 수 있습니다. 1명만 생성해도 진행이 가능합니다.",
    },
    {
      src: howToPayInService_02,
      title: "2. 마이메뉴에서 [상품 구매] 선택",
      text: "상품 리스트가 나올 때, 희망하시는 이용권을 선택해 주시면 됩니다.",
    },
    {
      src: howToPayInService_03,
      title: "3. 희망하시는 상품 선택",
      text: "상세 내용을 확인해 보신 후 [이용권 구매하기] 를 눌러주세요.",
    },
    {
      src: howToPayInService_04,
      title: "4. 결제 진행",
      text: "카드로 결제가 가능합니다. 결제 중 불편사항은 365일 1811-0729 로 문의해 주시면 빠르게 도움을 드리겠습니다.",
    },
    {
      src: howToPayInService_05,
      title: "5. 결제 완료 시 즉시 이용 가능",
      text: "이제부터 토들리에 정독 읽기를 통해 영어와 즐거운 학습의 시간을 만들 수 있습니다.",
    },
  ];

  return (
    <div className="contents">
      <div className="list">
        {renderDatas.map((item, index) => (
          <div className="row" key={index}>
            <div className="left-item">
              <img
                className="left-photo"
                src={item.src}
                alt={`이미지 ${index + 1}`}
              />
            </div>
            <div className="right-item">
              <h2 className="right-title">{item.title}</h2>
              <p className="right-side-text">{item.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowToRegister;
