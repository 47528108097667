import idc_01 from "./idc_01.jpeg";
import idc_02 from "./idc_02.jpeg";
import idc_03 from "./idc_03.jpeg";
import idc_04 from "./idc_04.jpeg";
import idc_05 from "./idc_05.jpeg";
import idc_06 from "./idc_06.jpeg";

const idcImages = [idc_01, idc_02, idc_03, idc_04, idc_05, idc_06];

export default idcImages;
