import styled from "styled-components";
import xbtn from "../../assets/images/x_button.png";
import howToPayInService_01 from "../../assets/images/howToPayInService/howToPayInService_01.png";
import howToPayInService_02 from "../../assets/images/howToPayInService/howToPayInService_02.png";
import howToPayInService_03 from "../../assets/images/howToPayInService/howToPayInService_03.png";
import howToPayInService_04 from "../../assets/images/howToPayInService/howToPayInService_04.png";
import howToPayInService_05 from "../../assets/images/howToPayInService/howToPayInService_05.png";
import HowToRegister from "../../components/HowToRegister/HowToRegister";
import { useEffect } from "react";
/**
----------------------------------------------- 🛠️ 타입 🛠️ -----------------------------------------------
----------------------------------------------- 🛠️ 타입 🛠️ -----------------------------------------------
*/
interface IHowToBuyModal {
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  close: () => void;
}

/**
 ----------------------------------------------- 🖼️ 렌더링 🖼️ -----------------------------------------------
 ----------------------------------------------- 🖼️ 렌더링 🖼️  -----------------------------------------------
 */

export default function HowToBuyModal({
  width = "75dvw",
  height = "80dvh",
  maxWidth,
  maxHeight,
  close,
}: IHowToBuyModal) {
  const renderDatas = [
    {
      src: howToPayInService_01,
      title: "1. PC 또는 App 에서 로그인",
      text: "https://edu.toddlier.co.kr 에서 회원가입 후 로그인을 해 주세요. 자녀는 2명까지 프로필을 생성할 수 있습니다. 1명만 생성해도 진행이 가능합니다.",
    },
    {
      src: howToPayInService_02,
      title: "2. 마이메뉴에서 [상품 구매] 선택 ",
      text: "상품 리스트가 나올 때, 희망하시는 이용권을 선택해 주시면 됩니다.",
    },
    {
      src: howToPayInService_03,
      title: "3. 희망하시는 상품 선택",
      text: "상세 내용을 확인해 보신 후 [이용권 구매하기] 를 눌러주세요.",
    },

    {
      src: howToPayInService_04,
      title: "4. 결제 진행",
      text: "카드로 결제가 가능합니다. 결제 중 불편사항은 365일 1811-0729 로 문의해 주시면 빠르게 도움을 드리겠습니다.",
    },
    {
      src: howToPayInService_05,
      title: "5. 결제 완료 시 즉시 이용 가능",
      text: "이제부터 토들리에 정독 읽기를 통해 영어와 즐거운 학습의 시간을 만들 수 있습니다.",
    },
  ];

  /**
   * 모달 밖 영역 스크롤 방지
   */
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <Backdrop>
      <Contents
        $width={width}
        $height={height}
        $maxWidth={maxWidth}
        $maxHeight={maxHeight}
      >
        <Header>
          <CloseBtn src={xbtn} alt="닫기" onClick={close} />
          <TitleWrap>
            <Title>이용권 직접 구매</Title>
          </TitleWrap>
        </Header>

        {/* <List>
          {renderDatas.map((item, index) => {
            return (
              <Row key={index}>
                <LeftItem>
                  <LeftPhoto src={item.src} alt="이미지" />
                </LeftItem>
                <RightItem>
                  {item.title ? <RightTitle>{item.title}</RightTitle> : null}
                  <RightText>{item.text}</RightText>
                </RightItem>
              </Row>
            );
          })}
        </List> */}
        <div style={{ width: "100%", height: "100%" }} id="content">
          <iframe
            src="https://toddlier.co.kr/how-to-register?hide-header=true"
            // src="http://localhost:3000/how-to-register?hide-header=true"
            style={{ width: "100%", height: "100%", border: "none" }}
            title="Privacy"
          />
        </div>
      </Contents>
    </Backdrop>
  );
}

/**
----------------------------------------------- 🎨 스타일 🎨 -----------------------------------------------
----------------------------------------------- 🎨 스타일 🎨 -----------------------------------------------
*/
const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const Header = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0px;
  background-color: white;
`;

const Contents = styled.div<{
  $width: string;
  $height: string;
  $maxWidth?: string;
  $maxHeight?: string;
}>`
  display: flex;
  flex-direction: column;
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  max-width: ${({ $maxWidth }) => ($maxWidth ? $maxWidth : "1200px")};
  max-height: ${({ $maxHeight }) => ($maxHeight ? $maxHeight : "900px")};
  border-radius: 8px;

  background-color: white;
  position: relative;

  overflow: auto;
  box-sizing: border-box;
  padding: 20px;
  padding-top: 0px;
`;

const TitleWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  margin: 16px 0px;
`;

const CloseBtn = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #aaa;
  cursor: pointer;

  width: 40px;
  aspect-ratio: 1/1;
`;

const List = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;

  /* background-color: lightcoral; */
`;

const Row = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2%;
`;

const LeftItem = styled.div`
  width: 100%;

  /* background-color: lightgreen; */
`;

const LeftPhoto = styled.img`
  object-fit: contain;
  width: 100%;
`;

const RightItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  /* background-color: azure; */
`;

const RightTitle = styled.h2`
  text-align: left;
  font-weight: 700;
  font-size: 1.2rem;
  margin: 0px;
`;

const RightText = styled.p`
  font-size: 1rem;
  margin: 0px;
`;
