import React, { useEffect, useState, useRef } from "react";
import "./LandingDetail05.css";
import kid from "../../assets/images/kid.png";
import robot from "../../assets/images/robot.png";
import apple from "../../assets/images/apple_standing.png";
import berry from "../../assets/images/berry_standing.png";
import speakingInput from "../../assets/images/speakingInput.png";
import chattingTogether from "../../assets/images/chattingTogether.png";

const LandingDetail05 = () => {
  const [visibleIndex, setVisibleIndex] = useState(-1); // 초기 상태: 애니메이션 비활성화
  const conversationRef = useRef<HTMLDivElement | null>(null); // 대화 섹션 DOM 참조

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          startAnimation(); // 스크롤로 인해 화면에 보이면 애니메이션 시작
        }
      },
      { threshold: 1 } // 50% 이상 보일 때 트리거
    );

    if (conversationRef.current) {
      observer.observe(conversationRef.current);
    }

    return () => {
      if (conversationRef.current) {
        observer.unobserve(conversationRef.current);
      }
    };
  }, []);

  const startAnimation = () => {
    const runAnimation = () => {
      setVisibleIndex(0); // 애니메이션 초기화
      let index = 0;

      const interval = setInterval(() => {
        index += 1;
        if (index > 3) {
          clearInterval(interval); // 모든 말풍선 표시 후 정지
        } else {
          setVisibleIndex(index);
        }
      }, 1000); // 각 말풍선 간격을 1초로 설정
    };

    runAnimation();
  };

  return (
    <div className="landing-detail-05">
      <p className="theTitle">
        질문하는 습관과 제대로 대답하는 법은 <br /> AI TUTOR을 통해 연습하세요.
      </p>
      <div className="conversation-section" ref={conversationRef}></div>

      {/* Features Section */}
      <div className="features-section">
        <div className="feature">
          <h3 className="feature-title title-font-size">개인 맞춤형 대화</h3>
          <div className="conversation-box feature-box">
            {[
              { text: "비행기가 영어로 뭐야?", img: kid, type: "user" },
              {
                text: `비행기는 영어로 "airplane" 이라고 해.<br /><br />혹시 비행기에 대해 더 궁금한 거 있어?`,
                type: "ai",
                isBlank: true,
              },
              {
                text: `'비행기가 뭐야?’는 <br />영어로 "What is an airplane?"이야.`,
                img: robot,
                type: "ai",
              },
              { text: "What is an airplane?", img: kid, type: "user" },
            ].map((bubble, index) => (
              <div
                key={index}
                className={`speech-bubble ${
                  index <= visibleIndex ? "visible" : "hidden"
                } ${bubble.type}-text`}
              >
                {bubble.isBlank ? (
                  <div className="img-blank"></div> // 빈 div로 대체
                ) : (
                  bubble.img && (
                    <img
                      src={bubble.img}
                      alt={`${bubble.type} Profile`}
                      className="profile-pic"
                    />
                  )
                )}
                <p dangerouslySetInnerHTML={{ __html: bubble.text }} />
              </div>
            ))}
          </div>
        </div>

        <div className="feature">
          <h3 className="feature-title">AI 스피킹</h3>
          <div className="speaking-test feature-box">
            <p className="feature-description">
              눈으로 ‘읽고’ 입으로 말하고<br></br>발음 교정부터 내용 이해까지
              <br></br> AI는 놓치지 않습니다.
            </p>
          </div>
        </div>
        <div className="feature">
          <h3 className="feature-title">자유 토론</h3>
          <div className="free-discussion feature-box">
            <p className="feature-description">
              한국어로 끊임없이 ‘질문’하세요.<br></br>‘AI’가 ‘질문’하는 법과
              <br></br>
              ‘대답’하는 법을 교정해줍니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingDetail05;
