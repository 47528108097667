import React, { useState } from "react";
import "./HowToBuy.css";
import naverShoppingIcon from "../../assets/images/naver-shopping-icon.svg"; // 네이버 쇼핑 아이콘 이미지
import todlierPurchaseIcon from "../../assets/images/logo.png"; // 서비스 내 구매 아이콘 이미지
import HowToBuyModal from "./HowToBuyModal";

const HowToBuy = () => {
  const [showInAppModal, setShowInAppModal] = useState(false);
  // 네이버 쇼핑으로 이동하는 함수
  const handleNaverShoppingClick = () => {
    window.open("https://smartstore.naver.com/toddlier", "_blank");
  };

  // 앱 안에서 구매 모달 열기
  const handleShowInAppPurchaseModal = () => {
    setShowInAppModal(true);
  };

  const handleCLoseInAppPurchaseModal = () => {
    setShowInAppModal(false);
  };

  return (
    <section className="how-to-buy">
      <div className="how-to-buy-options">
        {/* 네이버 쇼핑 옵션 */}
        <div
          className="purchase-option"
          onClick={handleNaverShoppingClick} // 클릭 이벤트 추가
          style={{ cursor: "pointer" }} // 커서를 손 모양으로 변경
        >
          <img
            src={naverShoppingIcon}
            alt="Naver Shopping"
            className="purchase-icon"
          />
          <div className="purchase-text">
            <h3>네이버 쇼핑</h3>
            <div className="justShow">
              <p>'토들리에'를 검색하여 쉽게 구매할 수 있습니다.</p>
              <p>이 버튼을 누르면 해당 사이트로 이동합니다.</p>
            </div>
            <div className="normalize">
              <p>
                '토들리에'를 검색하여 쉽게<br></br>구매할 수 있습니다.
              </p>
              <p>
                이 버튼을 누르면<br></br>해당 사이트로 이동합니다.
              </p>
            </div>
          </div>
        </div>

        {/* 서비스 내 구매 옵션 */}
        <div
          className="purchase-option in-app-purchase"
          onClick={handleShowInAppPurchaseModal}
        >
          <img
            src={todlierPurchaseIcon}
            alt="Todlier Purchase"
            className="purchase-icon"
          />
          <div className="purchase-text">
            <h3>앱 안에서 구매</h3>
            <div className="justShow">
              <p>토들리에 앱에서 이용권을 직접 구매할 수 있습니다.</p>
              <p>이 버튼을 누르면 상세한 설명이 나옵니다.</p>
            </div>
            <div className="normalize">
              <p>
                토들리에 앱에서 이용권을<br></br>직접 구매할 수 있습니다.
              </p>
              <p>
                이 버튼을 누르면<br></br>상세한 설명이 나옵니다.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* {showInAppModal ? (
        <TextModal
          content="1. PC 또는 App 에서 로그인 | 2. 마이메뉴에서 [상품 구매] 선택 | 3. 희망하시는 상품 선택 | 4. 결제 진행 (결제 완료 시 보여지는 '결제완료' 모달 있으면 보여주기) | 5. 결제 완료 시 즉시 이용 가능"
          title="이용권 직접 구매"
          onClose={() => setShowInAppModal(false)}
        />
      ) : null} */}

      {showInAppModal ? (
        <HowToBuyModal close={handleCLoseInAppPurchaseModal} />
      ) : null}
    </section>
  );
};

export default HowToBuy;
