import React from "react";
import "./HowToStart.css";

import howToStart01 from "../../assets/images/how-to-start-01.png";
import howToStart02 from "../../assets/images/how-to-start-02.png";
import howToStart03 from "../../assets/images/how-to-start-03.png";
import howToStart04 from "../../assets/images/how-to-start-04.png";
import howToStart05 from "../../assets/images/how-to-start-05.png";

const HowToStart = () => {
  return (
    <div className="how-to-start">
      <div className="section-header">
        <div className="theTitle">어떻게 시작하면 될까요?</div>
        <div className="theTitleSub">이용권을 구매하여 시작할 수 있습니다</div>
      </div>
      <div className="content">
        <div className="left-content">
          <div className="position-center">
            <span>
              가입 시 도움이 필요하시다면
              <br></br>
              토들리에 교육 전문가와 상담해 보세요
            </span>
            <p className="HowToStartInfo">1811-0729</p>
          </div>
        </div>
        <div className="right-content">
          <div className="post">
            <p className="step">Step 1</p>
            <div className="step_title">
              부모님께서 회원가입을 진행합니다.
              <p className="step_sub_title">
                가입 이 후, 아이들에게 프로필을 생성해 줍니다.
              </p>
            </div>
            <img
              src={howToStart01}
              alt="회원가입 이미지"
              className="modal-image"
            />
          </div>
          <div className="post">
            <p className="step">Step 2</p>
            <div className="step_title">
              학생 정보를 입력합니다.
              <p className="step_sub_title">
                한 계정당 자녀 2명까지 프로필을 등록할 수 있습니다.
              </p>
            </div>
            <img
              src={howToStart02}
              alt="프로필 이미지"
              className="modal-image"
            />
          </div>
          <div className="post">
            <p className="step">Step 3</p>
            <div className="step_title">
              학생의 레벨을 선택합니다.
              <p className="step_sub_title">
                한 계정당 자녀 2명까지 등록할 수 있습니다.
              </p>
            </div>
            <img
              src={howToStart03}
              alt="레벨선택 이미지"
              className="modal-image"
            />
          </div>
          <div className="post">
            <p className="step">Step 4</p>
            <div className="step_title">
              이용권을 등록합니다.
              <p className="step_sub_title">
                사이트에서 직접 또는 네이버 쇼핑몰에서 '토들리에' 검색 후 구매가
                가능합니다.
              </p>
            </div>
            <img
              src={howToStart04}
              alt="이용권 등록 이미지"
              className="modal-image"
            />
          </div>
          <div className="post">
            <p className="step">시작</p>
            <div className="step_title">
              Let's get started!
              <p className="step_sub_title">
                이제 영어 정독 세계 탐험을 시작해 보아요!
              </p>
            </div>
            <img
              src={howToStart05}
              alt="이용권 등록 이미지"
              className="modal-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToStart;
