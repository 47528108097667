import React, { useEffect, useRef, useState } from "react";
import "./ContentsSamples.css";

// Sample 이미지들 import
import sample_01 from "../../assets/images/samples/webp/sample_01.webp";
import sample_02 from "../../assets/images/samples/webp/sample_02.webp";
import sample_03 from "../../assets/images/samples/webp/sample_03.webp";
import sample_04 from "../../assets/images/samples/webp/sample_04.webp";
import sample_05 from "../../assets/images/samples/webp/sample_05.webp";
import sample_06 from "../../assets/images/samples/webp/sample_06.webp";
import sample_07 from "../../assets/images/samples/webp/sample_07.webp";
import sample_08 from "../../assets/images/samples/webp/sample_08.webp";
import sample_09 from "../../assets/images/samples/webp/sample_09.webp";
import sample_10 from "../../assets/images/samples/webp/sample_10.webp";
import sample_11 from "../../assets/images/samples/webp/sample_11.webp";
import sample_12 from "../../assets/images/samples/webp/sample_12.webp";
import sample_13 from "../../assets/images/samples/webp/sample_13.webp";
import sample_14 from "../../assets/images/samples/webp/sample_14.webp";
import sample_15 from "../../assets/images/samples/webp/sample_15.webp";
import sample_16 from "../../assets/images/samples/webp/sample_16.webp";
import sample_17 from "../../assets/images/samples/webp/sample_17.webp";
import sample_18 from "../../assets/images/samples/webp/sample_18.webp";
import sample_19 from "../../assets/images/samples/webp/sample_19.webp";
import sample_20 from "../../assets/images/samples/webp/sample_20.webp";
import sample_21 from "../../assets/images/samples/webp/sample_21.webp";
import sample_22 from "../../assets/images/samples/webp/sample_22.webp";
import sample_23 from "../../assets/images/samples/webp/sample_23.webp";
import sample_24 from "../../assets/images/samples/webp/sample_24.webp";
import sample_25 from "../../assets/images/samples/webp/sample_25.webp";
import sample_26 from "../../assets/images/samples/webp/sample_26.webp";
import sample_27 from "../../assets/images/samples/webp/sample_27.webp";
import sample_28 from "../../assets/images/samples/webp/sample_28.webp";
import sample_29 from "../../assets/images/samples/webp/sample_29.webp";
import sample_30 from "../../assets/images/samples/webp/sample_30.webp";
import sample_31 from "../../assets/images/samples/webp/sample_31.webp";
import sample_32 from "../../assets/images/samples/webp/sample_32.webp";
import sample_33 from "../../assets/images/samples/webp/sample_33.webp";
import sample_34 from "../../assets/images/samples/webp/sample_34.webp";
import sample_35 from "../../assets/images/samples/webp/sample_35.webp";
import sample_36 from "../../assets/images/samples/webp/sample_36.webp";
import sample_37 from "../../assets/images/samples/webp/sample_37.webp";
import sample_38 from "../../assets/images/samples/webp/sample_38.webp";
import sample_39 from "../../assets/images/samples/webp/sample_39.webp";
import sample_40 from "../../assets/images/samples/webp/sample_40.webp";
import sample_41 from "../../assets/images/samples/webp/sample_41.webp";
import sample_42 from "../../assets/images/samples/webp/sample_42.webp";
import sample_43 from "../../assets/images/samples/webp/sample_43.webp";
import sample_44 from "../../assets/images/samples/webp/sample_44.webp";
import sample_45 from "../../assets/images/samples/webp/sample_45.webp";
import sample_46 from "../../assets/images/samples/webp/sample_46.webp";
import sample_47 from "../../assets/images/samples/webp/sample_47.webp";
import sample_48 from "../../assets/images/samples/webp/sample_48.webp";
import sample_49 from "../../assets/images/samples/webp/sample_49.webp";
import sample_50 from "../../assets/images/samples/webp/sample_50.webp";

// 큰 이미지
import big_01 from "../../assets/images/samples/sample_01.png";
import big_02 from "../../assets/images/samples/sample_02.png";
import big_03 from "../../assets/images/samples/sample_03.png";
import big_04 from "../../assets/images/samples/sample_04.png";
import big_05 from "../../assets/images/samples/sample_05.png";
import big_06 from "../../assets/images/samples/sample_06.png";
import big_07 from "../../assets/images/samples/sample_07.png";
import big_08 from "../../assets/images/samples/sample_08.png";
import big_09 from "../../assets/images/samples/sample_09.png";
import big_10 from "../../assets/images/samples/sample_10.png";
import big_11 from "../../assets/images/samples/sample_11.png";
import big_12 from "../../assets/images/samples/sample_12.png";
import big_13 from "../../assets/images/samples/sample_13.png";
import big_14 from "../../assets/images/samples/sample_14.png";
import big_15 from "../../assets/images/samples/sample_15.png";
import big_16 from "../../assets/images/samples/sample_16.png";
import big_17 from "../../assets/images/samples/sample_17.png";
import big_18 from "../../assets/images/samples/sample_18.png";
import big_19 from "../../assets/images/samples/sample_19.png";
import big_20 from "../../assets/images/samples/sample_20.png";
import big_21 from "../../assets/images/samples/sample_21.png";
import big_22 from "../../assets/images/samples/sample_22.png";
import big_23 from "../../assets/images/samples/sample_23.png";
import big_24 from "../../assets/images/samples/sample_24.png";
import big_25 from "../../assets/images/samples/sample_25.png";
import big_26 from "../../assets/images/samples/sample_26.png";
import big_27 from "../../assets/images/samples/sample_27.png";
import big_28 from "../../assets/images/samples/sample_28.png";
import big_29 from "../../assets/images/samples/sample_29.png";
import big_30 from "../../assets/images/samples/sample_30.png";
import big_31 from "../../assets/images/samples/sample_31.png";
import big_32 from "../../assets/images/samples/sample_32.png";
import big_33 from "../../assets/images/samples/sample_33.png";
import big_34 from "../../assets/images/samples/sample_34.png";
import big_35 from "../../assets/images/samples/sample_35.png";
import big_36 from "../../assets/images/samples/sample_36.png";
import big_37 from "../../assets/images/samples/sample_37.png";
import big_38 from "../../assets/images/samples/sample_38.png";
import big_39 from "../../assets/images/samples/sample_39.png";
import big_40 from "../../assets/images/samples/sample_40.png";
import big_41 from "../../assets/images/samples/sample_41.png";
import big_42 from "../../assets/images/samples/sample_42.png";
import big_43 from "../../assets/images/samples/sample_43.png";
import big_44 from "../../assets/images/samples/sample_44.png";
import big_45 from "../../assets/images/samples/sample_45.png";
import big_46 from "../../assets/images/samples/sample_46.png";
import big_47 from "../../assets/images/samples/sample_47.png";
import big_48 from "../../assets/images/samples/sample_48.png";
import big_49 from "../../assets/images/samples/sample_49.png";
import big_50 from "../../assets/images/samples/sample_50.png";

import AutoPlayCarousel from "../Carousel/AutoPlayCarousel";
import ImageModal from "../ImageModal/ImageModal";

const ContentsSamples: React.FC = () => {
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const [sliderModalImg, setSliderModalImg] = useState<string | null>(null);

  const row1Imgs = [
    sample_01,
    sample_02,
    sample_03,
    sample_04,
    sample_05,
    sample_06,
    sample_07,
    sample_08,
    sample_09,
    sample_10,
  ];
  const row2Imgs = [
    sample_11,
    sample_12,
    sample_13,
    sample_14,
    sample_15,
    sample_16,
    sample_17,
    sample_18,
    sample_19,
    sample_20,
  ];
  const row3Imgs = [
    sample_21,
    sample_22,
    sample_23,
    sample_24,
    sample_25,
    sample_26,
    sample_27,
    sample_28,
    sample_29,
    sample_30,
  ];
  const row4Imgs = [
    sample_31,
    sample_32,
    sample_33,
    sample_34,
    sample_35,
    sample_36,
    sample_37,
    sample_38,
    sample_39,
    sample_40,
  ];
  const row5Imgs = [
    sample_41,
    sample_42,
    sample_43,
    sample_44,
    sample_45,
    sample_46,
    sample_47,
    sample_48,
    sample_49,
    sample_50,
  ];

  const bigImages = [
    big_01,
    big_02,
    big_03,
    big_04,
    big_05,
    big_06,
    big_07,
    big_08,
    big_09,
    big_10,
    big_11,
    big_12,
    big_13,
    big_14,
    big_15,
    big_16,
    big_17,
    big_18,
    big_19,
    big_20,
    big_21,
    big_22,
    big_23,
    big_24,
    big_25,
    big_26,
    big_27,
    big_28,
    big_29,
    big_30,
    big_31,
    big_32,
    big_33,
    big_34,
    big_35,
    big_36,
    big_37,
    big_38,
    big_39,
    big_40,
    big_41,
    big_42,
    big_43,
    big_44,
    big_45,
    big_46,
    big_47,
    big_48,
    big_49,
    big_50,
  ];

  const handleClickSliderImg = (imgSource: string) => {
    if (!imgSource) {
      console.error("이미지 없음");
      return;
    }

    const match = imgSource.match(/sample_(\d+)\./);
    if (match) {
      const imgNumber = parseInt(match[1], 10) - 1; // 배열 인덱스를 맞추기 위해 1을 뺍니다.
      const bigSource = bigImages[imgNumber];

      if (bigSource) {
        setSliderModalImg(bigSource);
      } else {
        console.error("해당 번호에 맞는 big 이미지가 없습니다.");
      }
    } else {
      console.error("이미지 번호를 추출할 수 없습니다.");
    }
  };

  const onCloseImageModal = () => {
    setSliderModalImg(null);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in");
            observer.unobserve(entry.target); // 관찰 해제
          }
        });
      },
      { threshold: 0 }
    );

    const slider = sliderRef.current;
    if (slider) {
      observer.observe(slider); // slider-container도 관찰 대상 추가
    }
  }, []);

  return (
    <section className="contents-samples">
      <div className="slider-container" ref={sliderRef}>
        {/* AutoPlayCarousel 컴포넌트들 */}
      </div>

      {sliderModalImg && (
        <ImageModal
          imageSrc={sliderModalImg}
          altText="이미지"
          onClose={onCloseImageModal}
        />
      )}
      <div className="slider-container" ref={sliderRef}>
        <AutoPlayCarousel
          handleClickSliderImg={handleClickSliderImg}
          source={row1Imgs}
          direction="left"
        />
        <AutoPlayCarousel
          handleClickSliderImg={handleClickSliderImg}
          source={row2Imgs}
          direction="right"
        />
        {/* <AutoPlayCarousel
          handleClickSliderImg={handleClickSliderImg}
          source={row3Imgs}
          direction="left"
        />
        <AutoPlayCarousel
          handleClickSliderImg={handleClickSliderImg}
          source={row4Imgs}
          direction="right"
        />
        <AutoPlayCarousel
          handleClickSliderImg={handleClickSliderImg}
          source={row5Imgs}
          direction="left"
        /> */}
      </div>

      {sliderModalImg ? (
        <ImageModal
          imageSrc={sliderModalImg}
          altText="이미지"
          onClose={onCloseImageModal}
        />
      ) : null}
      <p className="small-text">(이미지를 눌러서 확대해 보세요)</p>
    </section>
  );
};

export default ContentsSamples;
