import React from "react";
import "./Hero.css";

const Hero = () => {
  // 버튼 클릭 시 실행될 함수
  const handleStartClick = () => {
    window.open("https://edu.toddlier.co.kr/signin", "_blank"); // 새 탭에서 열기
  };

  return (
    <section className="hero-intro-section">
      {/* <div className="hero-content">
        <div className="title-wrap">
          <h1>이중언어 기반</h1>
          <h1>영어 정독 읽기 프로그램</h1>
          <h2>매일 한 권씩 스토리텔링 기반으로 </h2>
          <h2>영어와 한글 교차 노출을 통한 학습 </h2>
          <button onClick={handleStartClick}>지금 시작하기</button>
        </div>
      </div> */}

      <div className="hero-inner-intro-section">
        <h1 className="hero-intro-title">
          아이의 미래를 여는
          <br />
          영어 읽기의 시작
        </h1>
        <p className="hero-intro-subtitle">
          토들리에가 걸음마부터 달리기까지, <br /> 언제나 곁에 있겠습니다.
        </p>
      </div>
    </section>
  );
};

export default Hero;
