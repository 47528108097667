import React, { useEffect, useRef } from "react";
import "./LandingDetail02.css"; // 새로운 CSS 파일을 불러옵니다.
import icon1 from "../../assets/images/carousel/icon/nuri_icon.png";
import icon2 from "../../assets/images/carousel/icon/science1_icon.png";
import icon3 from "../../assets/images/carousel/icon/non-fiction_icon.png";
import icon4 from "../../assets/images/carousel/icon/conversation_icon.png";
import icon5 from "../../assets/images/carousel/icon/adventure_icon.png";
import icon6 from "../../assets/images/carousel/icon/daily_icon.png";

const LandingDetail02 = () => {
  const iconsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            const target = entry.target as HTMLElement;
            target.style.animationDelay = `${index * 0.5}s`; // 0.5초 간격 애니메이션 시작
            target.classList.add("animate-icon");
          } else {
            const target = entry.target as HTMLElement;
            target.classList.remove("animate-icon");
          }
        });
      },
      { threshold: 0.1 } // 화면의 10%가 보이면 실행
    );

    const icons = iconsRef.current?.querySelectorAll(".icon-item img");
    if (icons) {
      icons.forEach((icon) => observer.observe(icon));
    }

    return () => {
      if (icons) {
        icons.forEach((icon) => observer.unobserve(icon));
      }
    };
  }, []);

  return (
    <section className="landing-detail02">
      <div className="wrapYellow">
        <p className="landing-detail-02-paragraph">
          <span className="yellowFont">영어 읽기</span>를
        </p>
        <p className="landing-detail-02-paragraph">
          <span className="yellowFont">어떻게 시작하느냐</span>
          <span className="justShow">에 달렸습니다</span>

          <div className="normalize">에 달렸습니다</div>
        </p>
      </div>
      <div className="contents-party">
        <div className="content-item02">
          <div className="comparison-board-01">
            <h2 className="comparison-board-h2">기존 영어도서관</h2>
            <h1 className="comparison-board-h1">“다독”</h1>
            <p className="comparison-board-p">
              수천 권 이상의 도서를 보유하고 유명 출판사, 유명 작가 도서 위주
              매일매일 읽기 권장 프로그램
            </p>
          </div>
        </div>
        <div className="content-item02">
          <div className="comparison-board-02">
            <h2 className="comparison-board-h2">토들리에</h2>
            <h1 className="comparison-board-h1">
              <span className="scaling-text">“정독”</span>
            </h1>
            <p className="comparison-board-p">
              매주 추천되는 '금주의 도서'<br></br>프로그램으로 하루 한 권씩
              <br></br>5단계의 정독 읽기 프로그램
            </p>
          </div>
        </div>
      </div>
      <div className="contents-banner">
        <div className="text-container">
          <span className="left-text">영어 원서</span>
          <span className="right-text">한국 번역</span>
        </div>
      </div>
      <div className="comparison-impact-box">
        <h2>에피소드 기반의 6개 카테고리</h2>
        <h2>&</h2>
        <h2>아이들의 읽기 흥미 증진을 위한 그림체</h2>
      </div>
      <div className="contents-banner-none">
        <div className="icon-container" ref={iconsRef}>
          <div className="icon-item">
            <img src={icon1} alt="누리과정" />
            <img src={icon2} alt="SF" />
            <img src={icon3} alt="Non-Fiction" />
            <img src={icon4} alt="회화" />
            <img src={icon5} alt="모험" />
            <img src={icon6} alt="일상" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingDetail02;
