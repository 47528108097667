import React, { useEffect, useRef } from "react";
import "./LevelLists.css";

import child1 from "../../assets/images/levellists/child1.png";
import child2 from "../../assets/images/levellists/child2.png";
import child3 from "../../assets/images/levellists/child3.png";
import child4 from "../../assets/images/levellists/child4.png";
import child5 from "../../assets/images/levellists/child5.png";
import { Fade } from "react-awesome-reveal";

const LevelLists = () => {
  const FADE_IN_DURATION = 1500;

  return (
    <section className="sectionArea">
      <div className="theTitle">우리 아이에게 적합한 레벨은?</div>
      <div className="theTitleSub">
        예문을 자연스럽게 읽거나 듣고 이해할 수 있다면<br></br>'해당 레벨'로 볼
        수 있습니다.
      </div>
      <Fade duration={FADE_IN_DURATION} triggerOnce>
        <div className="level-item">
          <div className="no-flex">
            <p className="level-title color_a">A</p>
            <div className="horizontal_line_a"></div>
            <p className="level-ar align-right color_a">AR 1.0</p>
          </div>
          <div>
            <img src={child1} alt="child1" />
          </div>
          <div className="level-description">
            <p className="level-text">“Hi, I am Tom.”</p>
            <p className="level-text">A-B-C-D-E-F-G...</p>
            <p className="level-text">"I want to play / eat / look"</p>
            <p className="level-text">Monday, Tuesday...</p>
            <p className="level-text">One, Two, ..., Hundred</p>
            <p className="level-text color_a mt">
              영어 단어를 읽고 그 의미를 이해할 수 있습니다. 알파벳을 숙지하고
              간단한 일상 단어를 인지합니다.
            </p>
          </div>
        </div>
      </Fade>

      <Fade duration={FADE_IN_DURATION} triggerOnce>
        <div className="level-item">
          <div className="no-flex">
            <p className="level-title color_b">B</p>
            <div className="horizontal_line_b"></div>
            <p className="level-ar align-right color_b">AR 1.5</p>
          </div>
          <div>
            <img src={child2} alt="child2" />
          </div>
          <div className="level-description">
            <p className="level-text">
              "I am from Korea. I live with my family."
            </p>
            <p className="level-text">"Do you want to read a book with me?"</p>
            <p className="level-text">"I would like to eat some pizza."</p>
            <p className="level-text">"I like the beat of this music."</p>
            <p className="level-text">"It sounds great!"</p>

            <p className="level-text color_b mt">
              기본 영어 단어 1000개로 간단한 의사 표현이 가능합니다. 간단한
              인사말과 자기소개 정도의 대화가 가능합니다.
            </p>
          </div>
        </div>
      </Fade>
      <Fade duration={FADE_IN_DURATION} triggerOnce>
        <div className="level-item">
          <div className="no-flex">
            <p className="level-title color_c">C</p>
            <div className="horizontal_line_c"></div>
            <p className="level-ar align-right color_c">AR 2.0</p>
          </div>
          <div>
            <img src={child3} alt="child3" />
          </div>
          <div className="level-description">
            <p className="level-text">"When do you think you can come?"</p>
            <p className="level-text">
              "It is twelve-thirty, and we are about to eat lunch."
            </p>
            <p className="level-text">
              "I agree with you. It was hard without it."
            </p>
            <p className="level-text">
              "We need to put this powder into the bowl with water. "
            </p>
            <p className="level-text">
              “In my opinion, I think you should take this bag.”
            </p>
            <p className="level-text color_c mt">
              기본 영어 숙어와 중급 단어 2000개로 일상적인 의사소통이
              가능합니다. 간단한 질문과 답변 및 대화에 참여할 수 있습니다.
            </p>
          </div>
        </div>
      </Fade>
      <Fade duration={FADE_IN_DURATION} triggerOnce>
        <div className="level-item">
          <div className="no-flex">
            <p className="level-title color_d">D</p>
            <div className="horizontal_line_d"></div>
            <p className="level-ar align-right color_d">AR 3.0</p>
          </div>
          <div>
            <img src={child4} alt="child4" />
          </div>
          <div className="level-description">
            <p className="level-text">
              “When you mix vinegar and baking soda, they create a chemical
              reaction that produces bubbles of carbon dioxide gas.”
            </p>
            <p className="level-text">
              “Our country has three levels of government: local, state, and
              national, and each one has different responsibilities.”
            </p>
            <p className="level-text">
              "she began to develop her experimental aircraft into a marketable
              product. "
            </p>
            <p className="level-text">
              "Food is any substance consumed by an organism for nutritional
              support. "
            </p>
            <p className="level-text color_d mt">
              영어로 일기를 쓰며, 현지 초등학교 4학년 수준의 문제를 이해하고 풀
              수 있습니다. 일상생활의 단순한 사건이나 감정을 영어로 표현할 수
              있습니다.
            </p>
          </div>
        </div>
      </Fade>
      <Fade duration={FADE_IN_DURATION} triggerOnce>
        <div className="level-item">
          <div className="no-flex">
            <p className="level-title color_e">E</p>
            <div className="horizontal_line_e"></div>
            <p className="level-ar align-right color_e">AR 4.0</p>
          </div>
          <div>
            <img src={child5} alt="child5" />
          </div>
          <div className="level-description">
            <p className="level-text">
              “Artificial Intelligence, or AI, is a branch of computer science
              that focuses on creating machines and software that can perform
              tasks that usually require human intelligence. For example, AI can
              be used to recognize faces in photos, suggest movies or songs you
              might like, or even help doctors diagnose diseases. AI systems can
              also learn from data, improving their performance over time
              without explicit programming, a process known as machine learning.
              ”
            </p>
            <p className="level-text color_e mt">
              다양한 상황에서 기본적인 영어 회화가 가능하며, 실제 영어 기사를
              읽고 이해할 수 있습니다. 일상 대화에 보다 적극적으로 참여할 수
              있습니다.
            </p>
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default LevelLists;
