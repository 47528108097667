import React, { useState, useEffect, useRef } from "react";
import "./LandingDetail04.css";

//@ts-ignore
import audioFile1 from "../../assets/sounds/english-1.mp4";
//@ts-ignore
import audioFile2 from "../../assets/sounds/english-2.mp4";
//@ts-ignore
import audioFile3 from "../../assets/sounds/korean-1.mp4";
//@ts-ignore
import audioFile4 from "../../assets/sounds/korean-2.mp4";

//@ts-ignore
import vocaVoice1 from "../../assets/sounds/audio_voca_1.wav";

//@ts-ignore
import vocaVoice2 from "../../assets/sounds/audio_voca_2.wav";

import image1 from "../../assets/images/ebook_category_sample_01.png";
import image2 from "../../assets/images/ebook_category_sample_02.png";
import image3 from "../../assets/images/ebook_category_sample_03.png";
import image4 from "../../assets/images/ebook_category_sample_04.png";
import image5 from "../../assets/images/ebook_category_sample_05.png";
import image6 from "../../assets/images/ebook_category_sample_06.png";

import icon1 from "../../assets/images/carousel/icon/nuri_icon.png";
import icon2 from "../../assets/images/carousel/icon/sf_icon.png";
import icon3 from "../../assets/images/carousel/icon/non-fiction_icon.png";
import icon4 from "../../assets/images/carousel/icon/conversation_icon.png";
import icon5 from "../../assets/images/carousel/icon/adventure_icon.png";
import icon6 from "../../assets/images/carousel/icon/daily_icon.png";

import mediaContents01 from "../../assets/images/media-contents_01-01.png";
import mediaContents02 from "../../assets/images/media-contents_01-02.png";

import imageToSound from "../../assets/images/imageToSound.png";

// @ts-ignore
import ebookVideo1 from "../../assets/media/ebook_01.mp4";
// @ts-ignore
import mediaVideo3 from "../../assets/media/media_03.mp4";
import speakingSample from "../../assets/images/ebook_03_01.png";
import speakingSampleScore from "../../assets/images/ebook_03_02.png";
import quizSample from "../../assets/images/ebook_04_01.png";
import quizSampleScore from "../../assets/images/ebook_04_02.png";
import koreanSample from "../../assets/images/ebook_05_01.png";
import koreanSamplePage from "../../assets/images/ebook_05_02.png";
import vocaSample from "../../assets/images/ebook_06.png";
import IdcCarousel from "../../components/UI/IdcCarousel/IdcCarousel";
import ChanIdcCarousel from "../../components/UI/IdcCarousel/ChanIdcCarousel";

const LandingDetail04 = () => {
  const [isScoreVisible, setIsScoreVisible] = useState(false); // 점수 보임 상태

  // const handleSampleClick = () => {
  //   setIsScoreVisible(!isScoreVisible); // 상태를 토글
  // };

  // 각 이미지에 대한 개별 상태
  const [isImage1Clicked, setIsImage1Clicked] = useState(false);
  const [isImage2Clicked, setIsImage2Clicked] = useState(false);
  const [isImage3Clicked, setIsImage3Clicked] = useState(false);
  const [isImage4Clicked, setIsImage4Clicked] = useState(false);

  // 이미지 클릭 핸들러
  const handleImage1Click = () => {
    setIsImage1Clicked(!isImage1Clicked);
  };

  const handleImage2Click = () => {
    setIsImage2Clicked(!isImage2Clicked);
  };

  const handleImage3Click = () => {
    setIsImage3Clicked(!isImage3Clicked);
  };

  const handleImage4Click = () => {
    setIsImage4Clicked(!isImage4Clicked);
  };

  const vocaVoiceAudioRef = useRef<HTMLAudioElement | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false); // 오디오 재생 상태
  const [currentAudioIndex, setCurrentAudioIndex] = useState(0); // 현재 재생 중인 오디오 인덱스
  const audioRefs = useRef([
    new Audio(audioFile1),
    new Audio(audioFile2),
    new Audio(audioFile3),
    new Audio(audioFile4),
  ]); // Audio 객체 배열

  const images = [image1, image2, image3, image4, image5, image6];
  const icons = [icon1, icon2, icon3, icon4, icon5, icon6];
  let slideTimer: NodeJS.Timeout | null = null;
  let isVocaPlaying = false;

  const handleIndexChange = (index: number) => {
    console.log("현재 인덱스:", index);
    // 필요한 로직 추가
  };

  // 자동 슬라이드 변경
  useEffect(() => {
    if (!isHovering) {
      startAutoSlide(2000); // 초기 2초 간격으로 시작
    }
    return () => {
      if (slideTimer) clearInterval(slideTimer);
    };
  }, [isHovering]);

  // 자동 슬라이드 시작 함수
  const startAutoSlide = (interval: number) => {
    if (slideTimer) clearInterval(slideTimer);
    slideTimer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);
  };

  // 호버 상태 설정
  const handleMouseEnter = () => {
    setIsHovering(true);
    if (slideTimer) clearInterval(slideTimer); // 애니메이션 중지
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  // 아이콘 클릭 핸들러: 클릭 시 이미지와 아이콘 업데이트
  const handleIconClick = (index: number) => {
    setCurrentIndex(index); // 선택한 아이콘에 해당하는 이미지 표시
    if (slideTimer) clearInterval(slideTimer); // 타이머 중지
    startAutoSlide(10000); // 클릭 이후 10초 간격으로 자동 슬라이드 재개
  };

  // 이미지 클릭 핸들러: 오디오 파일 순차 재생
  const handleImageClick = () => {
    console.log(isPlaying);
    console.log("오디오 재생");
    if (isPlaying) {
      stopAllAudio(); // 모든 오디오 정지
      setIsPlaying(false);
    } else {
      playAudio(0); // 첫 번째 오디오부터 재생
      setIsPlaying(true);
    }
  };

  const playVocaVoices = () => {
    if (isVocaPlaying) {
      console.log("광클 방지");
      return;
    }

    if (isVocaPlaying) {
      if (vocaVoiceAudioRef.current) {
        vocaVoiceAudioRef.current.pause();
        vocaVoiceAudioRef.current.currentTime = 0;
      }
      isVocaPlaying = false;
    } else {
      isVocaPlaying = true;
      if (vocaVoiceAudioRef.current) {
        // 첫 번째 오디오 재생
        vocaVoiceAudioRef.current.src = vocaVoice1;
        vocaVoiceAudioRef.current.play();

        // 첫 번째 오디오가 끝나면 두 번째 오디오 재생
        vocaVoiceAudioRef.current.onended = () => {
          vocaVoiceAudioRef.current!.src = vocaVoice2;
          vocaVoiceAudioRef.current!.play().then(() => {
            // 두 번째 오디오가 끝난 후 멈춤 처리
            vocaVoiceAudioRef.current!.onended = () => {
              vocaVoiceAudioRef.current!.pause();
              vocaVoiceAudioRef.current!.currentTime = 0;
              isVocaPlaying = false;
              console.log("모든 오디오가 재생 완료 후 멈췄습니다.");
            };
          });
        };
      }
    }
  };

  // 특정 오디오 파일 재생
  const playAudio = (index: number) => {
    console.log("오디오 파일 실행중");
    if (index >= audioRefs.current.length) {
      setIsPlaying(false); // 마지막 오디오 이후 재생 중지
      return;
    }

    const audio = audioRefs.current[index];
    audio.play();
    audio.onended = () => playAudio(index + 1); // 현재 파일이 끝나면 다음 파일 재생
  };

  // 모든 오디오 정지
  const stopAllAudio = () => {
    audioRefs.current.forEach((audio) => {
      audio.pause();
      audio.currentTime = 0; // 재생 위치 초기화
    });
  };

  return (
    <section className="sectionArea">
      <div className="theTitle">
        토들리에의 정독 읽기 프로그램으로 <br />
        영어 읽기 시작과 완성을 준비하세요.
      </div>

      <div className="theTitleSub">
        영어 학습의 네가지 핵심 단계를 토들리에 이중언어 <br />
        6단계의 읽기 정독 프로그램을 경험하세요.
      </div>

      <span className="highlight">한 문장 한 문장 천천히 읽기</span>
      <div className="divider_title">
        <span className="diamond"></span>
      </div>
      <div className="bulletin-header">1. 눈으로 읽어요</div>
      <div className="landing-detail04-container">
        <video controls>
          <source src={ebookVideo1} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {/* <div className="Sample-overlay">이미지를 눌러서 음원을 들어보세요</div> */}
        <p className="comparison-board-describe">
          <span className="justShow">
            페이지를 한 장 한 장 넘겨보며 눈으로 문장을 읽어요. <br />
            알고 있는 단어와 모르는 단어를 구분하고<br></br>삽화를 보고 내용을
            추측해요.
          </span>

          <span className="normalize">
            페이지를 한 장 한 장 넘겨보며 <br />
            눈으로 문장을 읽어요. <br />
            알고 있는 단어와 모르는 단어를 구분하고 <br /> 삽화를 보고 내용을
            추측해요.
          </span>
        </p>
        <div className="icon-single-line">
          {icons.map((icon, index) => (
            <div
              key={index}
              className={`icon-single ${
                index === currentIndex ? "active" : ""
              }`}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleIconClick(index)} // 클릭 이벤트 추가
            >
              <img src={icon} alt={`icon-${index + 1}`} />
            </div>
          ))}
        </div>
        <div className="slider">
          <div className="slider-image">
            <img src={images[currentIndex]} alt={`slide-${currentIndex + 1}`} />
          </div>
        </div>
      </div>

      <span className="highlight">귀를 쫑긋 자세히 듣기</span>
      <div className="divider_title">
        <span className="diamond"></span>
      </div>
      <div className="bulletin-header">2. 귀로 들어요</div>
      <div className="landing-detail04-container">
        <img
          className="imageToSoundItem"
          src={imageToSound}
          alt="클릭 또는 터치하여 음성 재생"
          onClick={handleImageClick}
        />
        <div className="Sample-overlay">이미지를 눌러서 음원을 들어보세요</div>
        <p className="comparison-board-describe">
          <span className="justShow">
            전체 재생 버튼 누른 후 페이지마다 출력되는 오디오 음성을 들으며,{" "}
            <br></br> 음성과 삽화를 보고 내용을 이해하는 연습을 시작해요.
          </span>

          <span className="normalize">
            전체 재생 버튼 누른 후 페이지마다<br></br>
            출력되는 오디오 음성을 들으며,<br></br>
            음성과 삽화를 보고 내용을<br></br> 이해하는 연습을 시작해요.
          </span>
        </p>
      </div>

      <span className="highlight">또박또박 입으로 말하기</span>
      <div className="divider_title">
        <span className="diamond"></span>
      </div>
      <div className="bulletin-header">3. 입으로 말해요</div>
      <div className="landing-detail04-container">
        <div onClick={handleImage1Click}>
          <img
            className={`SampleImage ${isImage1Clicked ? "shimmer" : ""}`}
            src={speakingSample}
            alt=" Sample"
            style={{ opacity: isImage1Clicked ? 0 : 1 }} // fade-out 효과
          />
          <img
            className={`SampleScore ${isImage1Clicked ? "shimmer" : ""}`}
            src={speakingSampleScore}
            alt=" Sample"
            style={{ opacity: isImage1Clicked ? 1 : 0 }} // fade-out 효과
          />
          <div className="Sample-overlay">
            이미지를 누르면 발음 점수가 나옵니다
          </div>
        </div>
        <p className="comparison-board-describe">
          <span className="justShow">
            전체 읽기 과정을 끝낸 후 누르기 유도 화면에 나온<br></br>마이크
            버튼을 선택하고 읽은 도서의 문장을 한 번 더 듣고<br></br>또박또박
            읽어요.
          </span>

          <span className="normalize">
            전체 읽기 과정을 끝낸 후<br></br>누르기 유도 화면에 나온마이크
            <br></br>
            버튼을 선택하고 읽은 도서의 문장을<br></br> 한 번 더 듣고 또박또박
            읽어요.
          </span>
        </p>
      </div>

      <span className="highlight">천천히 머리로 생각하기</span>
      <div className="divider_title">
        <span className="diamond"></span>
      </div>
      <div className="bulletin-header">4. 퀴즈를 풀어요</div>
      <div className="landing-detail04-container">
        <div onClick={handleImage2Click}>
          <img
            className={`SampleImage`}
            src={quizSample}
            alt=" Sample"
            style={{ opacity: isImage2Clicked ? 0 : 1 }}
          />
          <img
            className={`SampleScore`}
            src={quizSampleScore}
            alt=" Sample"
            style={{ opacity: isImage2Clicked ? 1 : 0 }}
          />
          <div className="Sample-overlay">
            이미지를 누르면 퀴즈결과가 나옵니다
          </div>
        </div>
        <p className="comparison-board-describe">
          <span className="justShow">
            오늘 읽은 책의 내용을 신중히 생각해 보며, <br />
            질문을 읽거나 듣고 힌트 이미지를 보며 정답을 맞춰보아요.
          </span>

          <span className="normalize">
            오늘 읽은 책의 내용을 신중히 생각해 보며, <br />
            질문을 읽거나 듣고 힌트 이미지를 보며
            <br /> 정답을 맞춰보아요.
          </span>
        </p>
      </div>

      <span className="highlight">이중언어로 읽어 보기</span>
      <div className="divider_title">
        <span className="diamond"></span>
      </div>
      <div className="bulletin-header">5. 한국어로 읽어요</div>
      <div className="landing-detail04-container">
        <div onClick={handleImage3Click}>
          <img
            className={`SampleImage`}
            src={koreanSample}
            alt=" Sample"
            style={{ opacity: isImage3Clicked ? 0 : 1 }}
          />
          <img
            className={`SampleScore`}
            src={koreanSamplePage}
            alt=" Sample"
            style={{ opacity: isImage3Clicked ? 1 : 0 }}
          />
          <div className="Sample-overlay">
            이미지를 누르면 한국어가 나옵니다
          </div>
        </div>
        <p className="comparison-board-describe">
          <span className="justShow">
            영어로 읽었던 책을 이번에는 한국어로 교차해서 읽어보며, <br />
            영어와 한국어 문장의 뜻이 어떻게 표현되는지에 대한 이해를 키워요.
          </span>

          <span className="normalize">
            영어로 읽었던 책을 이번에는
            <br />
            한국어로 교차해서 읽어보며, <br />
            영어와 한국어 문장의 뜻이
            <br />
            어떻게 표현되는지에 대한 이해를 키워요.
          </span>
        </p>
      </div>
      <span className="highlight">단어 비교하기</span>
      <div className="divider_title">
        <span className="diamond"></span>
      </div>
      <div className="bulletin-header">6. 단어장을 확인해요</div>
      <div className="landing-detail04-container">
        <audio ref={vocaVoiceAudioRef} />
        <img
          className={`SampleImage`}
          src={vocaSample}
          alt=" Sample"
          onClick={playVocaVoices}
        />
        <div className="Sample-overlay">
          이미지를 클릭하면 단어 음성이 나옵니다
        </div>
        <p className="comparison-board-describe">
          <span className="justShow">
            오늘 읽은 책의 핵심 단어를 영어와 한국어로 그 뜻을 듣고 파악해요.
            <br />
            예문을 통해 단어가 어떠한 상황에서 사용되는지를 이해하고 따라 읽는
            연습을 해요.
          </span>

          <span className="normalize">
            오늘 읽은 책의 핵심 단어를 <br />
            영어와 한국어로 그 뜻을 듣고 파악해요.
            <br />
            예문을 통해 단어가 어떠한 상황에서
            <br /> 사용되는지를 이해하고
            <br /> 따라 읽는 연습을 해요.
          </span>
        </p>
      </div>
      <div className="theTitle">
        토들리에의 STEAM 교육 콘텐츠도 <br /> 함께 활용해보세요
      </div>
      <div className="theTitleSub">
        영어 기반 STEAM 콘텐츠는 체험형 콘텐츠로, <br />
        매주 한 번 집에서 만나는 창의력 패키지입니다.
      </div>
      <span className="highlight">재미있는 미디어 콘텐츠</span>
      <div className="divider_title">
        <span className="diamond"></span>
      </div>
      <div className="bulletin-header">1. 영상 시청하기</div>
      <div className="landing-detail04-container">
        <div onClick={handleImage4Click}>
          <img
            className={`SampleImage`}
            src={mediaContents01}
            alt=" Sample"
            style={{ opacity: isImage4Clicked ? 0 : 1 }}
          />
          <img
            className={`SampleScore`}
            src={mediaContents02}
            alt=" Sample"
            style={{ opacity: isImage4Clicked ? 1 : 0 }}
          />
          <div className="Sample-overlay">
            이미지를 누르면 상세화면이 나옵니다
          </div>
        </div>
        <p className="comparison-board-describe">
          <span className="justShow">
            매주 선정되는 주차별 미디어 콘텐츠를 시청하여 <br />
            전반적인 프로그램 이해도를 높입니다.
          </span>

          <span className="normalize">
            매주 선정되는 주차별
            <br />
            미디어 콘텐츠를 시청하여 <br />
            전반적인 프로그램 이해도를 높입니다.
          </span>
        </p>
      </div>

      <span className="highlight">연계 가능한 교재 &#40;워크북&#41;</span>
      <div className="divider_title">
        <span className="diamond"></span>
      </div>
      <div className="bulletin-header">2. 교재 활용하기</div>
      <div className="landing-detail04-container">
        <div className="idc-carousel">
          <ChanIdcCarousel handleIndexChange={handleIndexChange} />
        </div>
        <p className="comparison-board-describe">
          <div className="marginMinus">
            (화살표를 누르면 다음 이미지가 보입니다.)
          </div>
        </p>
      </div>
      <span className="highlight">만들기와 과학 실험을 집에서!</span>
      <div className="divider_title">
        <span className="diamond"></span>
      </div>
      <div className="bulletin-header">3. 만들기/실험하기</div>
      <div className="landing-detail04-container">
        <video controls>
          <source src={mediaVideo3} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <p className="comparison-board-describe">
          <span className="justShow">
            오늘의 주제와 관련된 실험이나 만들기를 통해 아이들의 <br />
            오감과 흥미를 자극하며 개념을 완성시킵니다.
          </span>

          <span className="normalize">
            오늘의 주제와 관련된 실험이나
            <br />
            만들기를 통해 아이들의 <br />
            오감과 흥미를 자극하며 <br />
            개념을 완성시킵니다.
          </span>
        </p>
      </div>
    </section>
  );
};

export default LandingDetail04;
