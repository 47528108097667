import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import KeyPoints from "./components/KeyPoints/KeyPoints";
// import Explanations from "./components/Explanations/Explanations";
import ContentsSamples from "./components/ContentsSamples/ContentsSamples";
import BulletinBoard from "./components/BulletinBoard/BulletinBoard";
import ProgramIntroduction from "./components/ProgramIntroduction/ProgramIntroduction";
import Footer from "./components/Footer/Footer";
import "./GlobalStyles.css";
import ProgramIntroductionSteps from "./components/ProgramIntroduction/ProgramIntroductionSteps/ProgramIntroductionSteps";
import ProgramIntroductionContents from "./components/ProgramIntroduction/ProgramIntroductionContents/ProgramIntroductionContents";
import ProgramIntroductionOnlineOffline from "./components/ProgramIntroduction/ProgramIntroductionOnlineOffline/ProgramIntroductionOnlineOffline";
import ProgramIntroductionContentsTable from "./components/ProgramIntroduction/ProgramIntroductionContentsTable/ProgramIntroductionContentsTable";
import ProgramIntroductionContentsTable2 from "./components/ProgramIntroduction/ProgramIntroductionContentsTable/ProgramIntroductionContentsTable2";
import Science2 from "./components/SubPrograms/Science2";
import Literacy2 from "./components/SubPrograms/Literacy2";
import EBook from "./components/SubPrograms/EBook";
import EventPage from "./components/EventPage/EventPage";
import NoticePage from "./components/NoticePage/NoticePage";
import LandingDetail01 from "./components/LandingDetail/LandingDetail01";
import LandingDetail02 from "./components/LandingDetail/LandingDetail02";
import Pricing from "./components/Pricing/Pricing";
import LandingDetail04 from "./components/LandingDetail/LandingDetail04";
import LandingDetail05 from "./components/LandingDetail/LandingDetail05";
import HowToStart from "./components/HowToStart/HowToStart";
import LevelLists from "./components/LevelLists/LevelLists";
import HowToBuy from "./components/HowToBuy/HowToBuy";
import LevelCascade from "./components/LevelCascade/LevelCascade";
import Ending from "./components/Ending/Ending";
import HowToBuyModal from "./components/HowToBuy/HowToBuyModal";
import HowToRegister from "./components/HowToRegister/HowToRegister";

function App() {
  const [isProgramVisible, setIsProgramVisible] = useState(false);

  const handleProgramVisible = (boolean: boolean) => {
    setIsProgramVisible(boolean);
  };

  return (
    <Router>
      <Header
        onProgramClick={handleProgramVisible}
        isProgramVisible={isProgramVisible}
      />
      <Routes>
        <Route
          path="/"
          element={
            isProgramVisible ? (
              <>
                <ProgramIntroduction />
                <ProgramIntroductionSteps />
                <ProgramIntroductionContents />
                <ProgramIntroductionOnlineOffline />
                <ProgramIntroductionContentsTable />
                <ProgramIntroductionContentsTable2 />
                <Footer />
              </>
            ) : (
              <>
                <Hero />
                <LandingDetail01 />
                <LandingDetail02 />
                <ContentsSamples />
                <LevelCascade />
                <LevelLists />
                <LandingDetail04 />
                <LandingDetail05 />
                <HowToStart />
                <Pricing />
                <HowToBuy />
                <ProgramIntroductionOnlineOffline />
                <Ending />
                <Footer />
              </>
            )
          }
        />

        {/* HowToStart 페이지 추가 */}
        <Route path="/how-to-register" element={<HowToRegister />} />

        {/* KHR : path명은 소문자 작성 및 띄어쓰기가 없이 작성해야 오류가 발생하지 않습니다. */}
        {/* "Creative Science English" 클릭 시 연결될 Science2 페이지 라우트 추가 */}
        <Route path="/science2" element={<Science2 />} />
        <Route path="/literacy2" element={<Literacy2 />} />
        <Route path="/ebook" element={<EBook />} />

        {/* 공지사항, 이벤트 추가 */}
        <Route path="/event" element={<EventPage />} />
        <Route path="/notice" element={<NoticePage />} />
      </Routes>
    </Router>
  );
}

export default App;
