import { styled } from "styled-components";
import { IdcCarouselItem } from "./idc_carousel.const";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useRef } from "react";
import type { Swiper as SwiperType } from "swiper"; // Swiper 타입 임포트
import CarouselArrow from "../CaroselArrow/CarouselArrow";

interface HorizonCarouselProps {
  handleIndexChange: (index: number) => void;
}

export default function HorizonCarousel({
  handleIndexChange,
}: HorizonCarouselProps) {
  const swiperRef = useRef<SwiperType | null>(null); // 타입 지정

  const handleNext = () => {
    console.log("다음", swiperRef.current);
    swiperRef.current?.slideNext();
  };

  const handlePrev = () => {
    swiperRef.current?.slidePrev();
  };

  const handleSlideTo = (index: number) => {
    swiperRef.current?.slideTo(index); // 해당 인덱스로 이동
    handleIndexChange(index); // 외부에서도 인덱스 업데이트
  };

  return (
    <SwiperWrap>
      <PrevBtnWrap className="prev-btn-wrap">
        <CarouselArrow onClick={handlePrev} type="left" />
      </PrevBtnWrap>
      <NextBtnWrap className="next-btn-wrap">
        <CarouselArrow onClick={handleNext} type="right" />
      </NextBtnWrap>
      <StyledSwiper
        onSwiper={(swiper: any) => (swiperRef.current = swiper)}
        onSlideChange={(swiper: any) => handleIndexChange(swiper.activeIndex)}
        // modules={[Pagination]}
        // pagination={{ clickable: true }}
        slidesPerView={5}
        centeredSlides={true}
        spaceBetween={0}
        initialSlide={2} // 3번째 이미지부터 시작
        loop={true} // 무한 순환 활성화
      >
        {Object.values(IdcCarouselItem).map((item, i) => (
          <SwiperSlide key={i} onClick={() => handleSlideTo(i)}>
            <Card src={item} alt={`icon_${i}`} />
          </SwiperSlide>
        ))}{" "}
      </StyledSwiper>
    </SwiperWrap>
  );
}

const SwiperWrap = styled.div`
  display: flex;
  overflow: visible;
  position: relative;
  justify-content: center;
  align-items: center;
  //   background-color: red;
`;

const Card = styled.img`
  width: ${`202px`};
  height: ${`300px`};
  @media (min-width: 1080px) {
    width: ${`202px`};
    height: ${`300px`};
  }

  object-fit: contain;
`;

const StyledSwiper = styled(Swiper)`
  //   width: ${`202px`};
  display: flex;
  justify-content: center;
  width: 1010px; // 얘가 반응형이어야 함
  height: ${`300px`};
  //   background-color: rgba(0, 0, 0, 0.5);
  flex-shrink: 0;
  @media (min-width: 1080px) {
    width: ${`1000px`};
    height: ${`300px`};
  }
  overflow: visible;
  .swiper-wrapper {
    gap: 20;
  }

  .swiper-slide,
  .swiper-slide-prev,
  .swiper-slide-next {
    opacity: 0.3;
    scale: 0.5;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* 그림자 */
    transition: opacity 0.6s ease; /* 0.3초 동안 부드럽게 변화 */
    transition: opacity 0.6s ease, scale 0.2s ease-in-out;
  }
  .swiper-slide-active {
    opacity: 1;
    scale: 1;
    // transition: opacity 0.6s ease;
    transition: opacity 0.6s ease, scale 0.2s ease-in-out;
  }
`;

const PrevBtnWrap = styled.div`
  position: absolute;
  width: 30px;
  z-index: 10;
  left: 20%;
  // 작업
  left: 0%;

  @media (max-width: 768px) {
    left: 20%;
    // 작업
    left: 0%;
  }

  @media (max-width: 425px) {
    left: 20%;
    // 작업
    left: 0%;
  }
`;

const NextBtnWrap = styled.div`
  position: absolute;
  width: 30px;
  z-index: 10;
  right: 20%;
  // 작업
  right: 0%;

  @media (max-width: 768px) {
    right: 10%;
    // 작업
    right: 0%;
  }

  @media (max-width: 425px) {
    right: -10%;
    // 작업
    right: 0%;
  }
`;
