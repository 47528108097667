import React, { useEffect, useRef } from "react";
import "./LandingDetail01.css";
import LevelIntroBanner from "../../assets/images/levelIntroBanner.png";

const LandingDetail01 = () => {
  // 타입을 HTMLElement로 명시
  const levelSectionsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
          } else {
            entry.target.classList.remove("animate");
          }
        });
      },
      { threshold: 1 } // 화면에 10%가 보이면 애니메이션 시작
    );

    // ref가 null이 아니면 querySelectorAll을 실행
    const elements = levelSectionsRef.current?.querySelectorAll(
      ".level-group, .level-next-arrow"
    );

    if (elements) {
      elements.forEach((el) => {
        observer.observe(el); // 애니메이션 감지
      });
    }

    return () => {
      if (elements) {
        elements.forEach((el) => {
          observer.unobserve(el); // 애니메이션 해제
        });
      }
    };
  }, []);

  return (
    <section className="sectionArea">
      <div className="landing-detail-header">
        <div className="header-image-container">
          <img
            className="banner-image"
            src={LevelIntroBanner}
            alt="레벨 소개 배너"
          />
          <div className="header-text-landing01">
            <h1>
              다양한 영어 읽기 프로그램,<br></br>
              어떤 프로그램을 선택해야 할까요?
            </h1>
          </div>
        </div>
      </div>
      <div className="theTitle">무조건 '많이' 읽는 것이 정답은 아닙니다.</div>
      <div className="theTitleSub">
        몇 천 권의 책을 읽었는지를 세어보기 전에 <br />
        얼마나 제대로 기억하고 있는지를 고려해야 합니다.
      </div>
    </section>
  );
};

export default LandingDetail01;
