import React, { useEffect, useRef } from "react";
import "./LevelCascade.css";
import LevelArrow from "../../assets/images/level-arrow-text.png";
import LevelNextArrow from "../../assets/images/levelNextArrow.png";

const LevelCascade = () => {
  // 타입을 HTMLElement로 명시
  const levelSectionsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
          } else {
            // entry.target.classList.remove("animate");
          }
        });
      },
      { threshold: 0.8 }
    );

    // ref가 null이 아니면 querySelectorAll을 실행
    const elements = levelSectionsRef.current?.querySelectorAll(
      ".level-group, .level-next-arrow"
    );

    if (elements) {
      elements.forEach((el) => {
        observer.observe(el); // 애니메이션 감지
      });
    }

    return () => {
      if (elements) {
        elements.forEach((el) => {
          observer.unobserve(el); // 애니메이션 해제
        });
      }
    };
  }, []);

  return (
    <section className="sectionArea">
      <div className="showThisFirstAndHideInSmall">
        <div className="theTitle">
          체계적인 레벨 설정을 통한 시작은<br></br> 영어 읽기의 '첫걸음' 입니다.
        </div>
        <div className="theTitleSub">
          토들리에 읽기 정독 프로그램은 영어를 처음 읽는{" "}
          <span className="theTitleSub-text">유치원생</span>
          부터<br></br>
          읽기 능력이 뛰어난{" "}
          <span className="theTitleSub-text">초등학생 전학년</span> 모두를 위한
          레벨로 구성되어 있습니다.
        </div>
      </div>

      <div className="showThisSecondAndHideTheFirst">
        <div className="theTitle">
          체계적인 레벨 설정을 통한 시작은<br></br> 영어 읽기의 '첫걸음' 입니다.
        </div>
        <div className="theTitleSub">
          토들리에 읽기 정독 프로그램은<br></br>영어를 처음 읽는{" "}
          <span className="theTitleSub-text">유치원생</span>
          부터<br></br>
          읽기 능력이 뛰어난{" "}
          <span className="theTitleSub-text">초등학생 전학년</span>
          <br></br>모두를 위한 레벨로 구성되어 있습니다.
        </div>
      </div>

      <div className="level-sections" ref={levelSectionsRef}>
        <img className="level-image" src={LevelArrow} alt="레벨 순서" />
        <div className="landing-detail-header">
          <div className="level-group">
            <div className="level-box">
              <div className="level-header A">레벨 A</div>
              <div className="level-content">
                영어 단어를 읽고 그 의미를 이해할 수 있어요.
                <br />
                알파벳을 숙지하고 간단한 일상 단어를 인지해요.
              </div>
            </div>
            <img
              className="level-next-arrow"
              src={LevelNextArrow}
              alt="화살표"
            />
          </div>
          <div className="level-group">
            <div className="level-box">
              <div className="level-header B">레벨 B</div>
              <div className="level-content">
                영단어 1000개로 의사 표현이 가능해요.
                <br />
                자기소개와 기본적인 일상대화가 가능해요.
              </div>
            </div>
            <img
              className="level-next-arrow"
              src={LevelNextArrow}
              alt="화살표"
            />
          </div>
          <div className="level-group">
            <div className="level-box">
              <div className="level-header C">레벨 C</div>
              <div className="level-content">
                기본 숙어와 영단어 2000개 정도를 알고 있어요.
                <br />
                특정 주제에 대한 기본적인 의사소통이 가능해요.
              </div>
            </div>
            <img
              className="level-next-arrow"
              src={LevelNextArrow}
              alt="화살표"
            />
          </div>
          <div className="level-group">
            <div className="level-box">
              <div className="level-header D">레벨 D</div>
              <div className="level-content">
                영어로 감정을 표현하며 일기를 쓸 수 있어요.
                <br />
                현지 초3학년 문제를 이해하고 풀 수 있어요.
              </div>
            </div>
            <img
              className="level-next-arrow"
              src={LevelNextArrow}
              alt="화살표"
            />
          </div>
          <div className="level-group">
            <div className="level-box">
              <div className="level-header E">레벨 E</div>
              <div className="level-content">
                다양한 상황에서 영어 회화가 가능합니다.
                <br />
                실제 영어 기사를 읽고 이해할 수 있습니다.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LevelCascade;
