import React, { useEffect, useRef } from "react";
import "./Ending.css";

import levelListDivider from "../../assets/images/levellists/levelListDivider.png";

const LevelLists = () => {
  const levelListDividerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // 기존 fade-in 클래스를 제거하고 다시 추가
            entry.target.classList.remove("fade-in");
            // Reflow를 강제로 트리거하여 애니메이션을 재시작
            void (entry.target as HTMLElement).offsetWidth;
            entry.target.classList.add("fade-in");
          }
        });
      },
      { threshold: 0.1 } // 20% 이상이 화면에 들어오면 애니메이션 시작
    );

    const levelListDividerElement = levelListDividerRef.current;
    if (levelListDividerElement) {
      observer.observe(levelListDividerElement); // levelListDivider 요소를 관찰
    }

    return () => {
      if (levelListDividerElement) {
        observer.unobserve(levelListDividerElement); // 컴포넌트 언마운트 시 관찰 해제
      }
    };
  }, []);

  return (
    <div className="level-list">
      <div className="levelListDivider" ref={levelListDividerRef}>
        <img src={levelListDivider} alt="구분자 이미지" />
      </div>
    </div>
  );
};

export default LevelLists;
