import React, { useState } from "react";
import PrivacyPolicyModal from "../PrivacyPolicy/Privacy";
import PolicyModal from "../PrivacyPolicy/Policy";
import "./Footer.css";

const Footer = () => {
  // 각 모달의 상태를 별도로 관리
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false);

  const openPrivacyModal = () => setIsPrivacyModalOpen(true);
  const closePrivacyModal = () => setIsPrivacyModalOpen(false);

  const openPolicyModal = () => setIsPolicyModalOpen(true);
  const closePolicyModal = () => setIsPolicyModalOpen(false);

  return (
    <footer className="footer">
      <div className="footer-left">
        <div className="footer-company">
          <span>네이처컴바인드 주식회사</span> {/* Bold 처리될 부분 */}
        </div>
        <div className="displayWide">
          <div className="footer-info">
            <span>대표자: 동방진</span>
            <span>경기 화성시 동탄첨단산업1로 57, 인큐베이팅센터 701호</span>
            <span>사업자등록번호: 881-86-02075</span>
          </div>
          <div className="footer-contact">
            <span>전화번호: 1811-0729</span>
            <span>이메일: edu@toddlier.co.kr</span>
            <span>통신판매업 신고번호 : 제2021-화성동탄-1107호</span>
          </div>
        </div>
        <div className="display1080">
          <div className="footer-info">
            <span>대표자: 동방진</span>
            <p>경기 화성시 동탄첨단산업1로 57, 인큐베이팅센터 701호</p>
            <p>사업자등록번호: 881-86-02075</p>
          </div>
          <div className="footer-contact">
            <span>전화번호: 1811-0729</span>
            <p>이메일: edu@toddlier.co.kr</p>
            <p>통신판매업 신고번호 : 제2021-화성동탄-1107호</p>
          </div>
        </div>
      </div>

      <hr />

      <div className="footer-right">
        {/* <span>회사소개</span>
        <span className="divider">|</span> */}
        <span
          onClick={openPolicyModal}
          style={{
            cursor: "pointer",
            textDecoration: "none", // 밑줄 없애기
          }}
        >
          이용약관
        </span>
        <span className="divider">|</span>
        <span
          onClick={openPrivacyModal}
          style={{
            cursor: "pointer",
            textDecoration: "none", // 밑줄 없애기
          }}
        >
          개인정보처리방침
        </span>
      </div>

      {/* 각 모달을 개별적으로 관리 */}
      <PolicyModal isOpen={isPolicyModalOpen} onClose={closePolicyModal} />
      <PrivacyPolicyModal
        isOpen={isPrivacyModalOpen}
        onClose={closePrivacyModal}
      />
    </footer>
  );
};

export default Footer;
