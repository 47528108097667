import React from "react";
import "./Pricing.css";
import digitalBooksAndContents from "../../assets/images/price/digital_books_and_contents.jpeg";
import BooksAndMaterials from "../../assets/images/price/books_and_materials.jpeg";

const Pricing = () => {
  return (
    <div className="pricing">
      {/* 이미지 섹션 */}
      <div className="grid-container-products">
        <div className="grid-item"></div>
        <div className="grid-item">
          <img src={digitalBooksAndContents} alt="체험형 영어 읽기 프로그램" />

          <p className="size_check">
            체험형<br></br>영어 읽기
          </p>
          <span className="price-title">디지털 서적</span>
          <span className="price-title">미디어 컨텐츠</span>
        </div>
        <div className="grid-item">
          <img src={BooksAndMaterials} alt="실습형 영어 교육 프로그램" />
          <p className="size_check">
            실습형<br></br>영어 교육
          </p>
          <span className="price-title">디지털 서적</span>
          <span className="price-title">미디어 컨텐츠</span>
          <span className="price-title">워크북</span>
          <span className="price-title">만들기 재료</span>
        </div>
      </div>

      {/* 가격 정보 섹션 */}
      <div className="grid-container-prices">
        {/* 1개월 가격 */}
        <div className="grid-item">
          {/* <span className="old-price">&nbsp;</span> */}
          <span className="new-price">1개월</span>
        </div>
        <div className="grid-item-price">
          <div className="price">
            {/* <span className="old-price">94,900</span> */}
            <span className="new-price">24,900</span>
          </div>
        </div>
        <div className="grid-item-price">
          <div className="price">
            {/* <span className="old-price">15,900</span> */}
            <span className="new-price">99,000</span>
          </div>
        </div>

        {/* 3개월 가격 */}
        <div className="grid-item">
          {/* <span className="old-price">&nbsp;</span> */}
          <span className="new-price">3개월</span>
        </div>
        <div className="grid-item-price">
          <div className="price">
            {/* <span className="old-price">284,700</span> */}
            <span className="new-price">69,000</span>
          </div>
        </div>
        <div className="grid-item-price">
          <div className="price">
            {/* <span className="old-price">47,700</span> */}
            <span className="new-price">279,000</span>
          </div>
        </div>

        {/* 6개월 가격 */}
        <div className="grid-item">
          {/* <span className="old-price">&nbsp;</span> */}
          <span className="new-price">6개월</span>
        </div>
        <div className="grid-item-price">
          <div className="price">
            {/* <span className="old-price">569,400</span> */}
            <span className="new-price">129,900</span>
          </div>
        </div>
        <div className="grid-item-price">
          <div className="price">
            {/* <span className="old-price">95,400</span> */}
            <span className="new-price">529,000</span>
          </div>
        </div>

        {/* 12개월 가격 */}
        <div className="grid-item">
          {/* <span className="old-price">&nbsp;</span> */}
          <span className="new-price">12개월</span>
        </div>
        <div className="grid-item-price">
          <div className="price">
            {/* <span className="old-price">1,138,800</span> */}
            <span className="new-price">249,000</span>
          </div>
        </div>
        <div className="grid-item-price">
          <div className="price">
            {/* <span className="old-price">190,800</span> */}
            <span className="new-price">990,000</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
