import idcImages from "../../../assets/images/idc_carousel/index";

export const IdcCarouselItem = {
  idc_01: idcImages[0],
  idc_02: idcImages[1],
  idc_03: idcImages[2],
  idc_04: idcImages[3],
  idc_05: idcImages[4],
  idc_06: idcImages[5],
};
