// PrivacyPolicyModal.tsx
import React from "react";
import Modal from "react-modal";
import "./PrivacyPolicy.css";

interface PrivacyPolicyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const PrivacyModal: React.FC<PrivacyPolicyModalProps> = ({
  isOpen,
  onClose,
}) => {
  const onAfterOpen = () => {
    document.body.style.overflow = "hidden"; // 모달이 열리면 스크롤 비활성화
  };

  const onAfterClose = () => {
    document.body.style.overflow = "auto"; // 모달이 닫히면 스크롤 복원
  };

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={() => {
        onClose();
        onAfterClose();
      }}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)", // 반투명 검은색 배경
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        content: {
          width: "70%", // 화면의 약 70% 크기
          maxHeight: "80vh",
          overflowY: "auto", // 모달 내부 스크롤 가능
          margin: "0 auto",
          padding: "20px",
          borderRadius: "8px",
        },
      }}
    >
      <h2>개인정보처리방침</h2>
      <div style={{ width: "100%", height: "100%" }} id="content">
        <iframe
          src="https://naturecombined.com/toddlier_privacy.html"
          style={{ width: "100%", height: "100%", border: "none" }}
          title="Privacy"
        />
      </div>
      <button onClick={onClose}>닫기</button>
    </Modal>
  );
};

export default PrivacyModal;
